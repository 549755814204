import img from "../assets/img/appicon.png"

const data = {
    name: "Steven LATOR",
    desc: "iOS Developer",
    social: {
        github: "https://github.com/ByTyson",
        linkdin: "https://www.linkedin.com/in/stevenltr/",
        email: "stevenlator.contact@gmail.com",
    },
    about: {
        title: "Mon parcours",
        description:
            "Passionné par l'écosystème Apple, je me distingue en tant que créateur et développeur curieux, toujours à la recherche de la prochaine idée à concrétiser. Après un début de carrière dans le développement web, j'ai rapidement orienté mon expertise vers le mobile, en publiant ma première application sur l'AppStore. Mon intérêt pour ce domaine m'a amené à explorer d'autres solutions tel que Flutter. Mon objectif ? Devenir LA référence iOS, tout en explorant de nouveaux horizons créatifs et techniques.",
    },

    titleSkill: "Mes compétences",
    skills:
        [
            {
                skillName: "iOS",
                skillDesc: "Swift & SwiftUI",
                skillIcon: "fa-brands:swift",
            },
            {
                skillName: "Frontend",
                skillDesc: "React Angular",
                skillIcon: "whh:html",
            },
            {
                skillName: "Backend",
                skillDesc: "NestJS",
                skillIcon: "fa6-solid:server"
            },
            {
                skillName: "BDD",
                skillDesc: "MySQL PostgreSQL MongoDB Firebase",
                skillIcon: "entypo:database"
            }
        ],

    titleHobby: "Mes passe-temps",
    hobbies: [
        {
            hobbyName: "Drone FPV",
            hobbyDesc: "Électronique Pilotage Montage vidéo",
            hobbyIcon: "healthicons:drone",
        },
        {
            hobbyName: "F1",
            hobbyDesc: "Compétition Stratégie",
            hobbyIcon: "fa-solid:car",


        },
        {
            hobbyName: "Moto",
            hobbyDesc: "Balade & Virage",
            hobbyIcon: "mdi:motorbike",
        },
        {
            hobbyName: "Tennis",
            hobbyDesc: "Autodiscipline Régularité",
            hobbyIcon: "mdi:tennis",
        },
        {
            hobbyName: "Domotique",
            hobbyDesc: "Docker HomeAssistant Raspberry",
            hobbyIcon: "fa-solid:home",
        }
    ],
    titleProject: "Mes projets",
    projects: [
        {
            projectName: "Opaddock",
            projectDesc: "Restructuration en cours",
            projectIcon: img,
            projectLangage: "Swift SwiftUI",
            projectLink: "https://apps.apple.com/fr/app/opaddock/id6478447160",
        }
    ],

    domaineName: "sltr.fr",
    domaineLink: "https://www.sltr.fr",
};

export default data;
